<template>
    <div class="main_container">
        <div class="main_page">
            <div class="page_title">{{ $route.meta.title }}</div>
            <div class="page_containt">
                <div class="page_search">
                    <el-row type="flex">
                        <el-col class="search_col">
                            <el-button type="primary" size="small" @click="doForm(null)">新增分类</el-button>
                        </el-col>
                    </el-row>
                </div>
                <div class="page_box" ref="pageBox">
                    <el-table v-loading="loading" default-expand-all row-key="id" size="small" class="el_table"
                        cell-class-name="el_table_cell" header-row-class-name="el_table_header" :data="listData"
                        :height="tableHeight" style="width: 100%">
                        <el-table-column prop="name" label="名称"> </el-table-column>
                        <el-table-column label="排序号" width="80">
                            <template slot-scope="scope">
                                <el-input size="small" v-model="scope.row.order_num"
                                    @blur="setItemOrder(scope.row)"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column fixed="right" align="right" header-align="right" label="操作" width="200">
                            <template slot-scope="scope">
                                <el-button class="btn" type="text" icon="el-icon-edit" size="small"
                                    @click="doForm(scope.row)">
                                    编辑
                                </el-button>
                                <el-button @click="deleteItem(scope.row)" class="btn" type="text" icon="el-icon-delete"
                                    size="small">
                                    删除
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
        <el-dialog :title="formTitle" :visible.sync="visible" :close-on-click-modal="false" :close-on-press-escape="false"
            width="400px">
            <el-form ref="modelForm" :rules="modelRules" :model="modelForm" label-width="100px" size="small">
                <el-form-item label="名称" size="small" prop="name">
                    <el-input v-model="modelForm.name" style="width: 200px" placeholder="名称"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" style="text-align: center">
                <el-button type="primary" native-type="submit" @click.native.prevent="handleSubmit" :loading="btnLogining"
                    size="small">确 定</el-button>
                <el-button @click="visible=false" size="small">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { onTableHeight } from "@/lib/table";
import resourceApi from "@/api/resource";
export default {
    data() {
        return {
            tableHeight: 500,
            loading: false,
            btnLogining: false,
            visible: false,
            formTitle: "",
            model: null,
            modelForm: {
                name: ""
            },
            modelRules: {
                name: [
                    { required: true, message: "请输入名称", trigger: "blur" },
                    { min: 1, max: 50, message: "长度不超过50个字符" },
                ]
            },
            listData: [],
        };
    },
    created() {
        this.getList();
    },
    mounted() {
        onTableHeight(this, false);
    },
    methods: {
        //加载表单
        doForm(row) {
            this.model = row;
            this.formTitle = this.model == null ? "新增分类" : "编辑分类";
            if (this.$refs.modelForm) this.$refs.modelForm.resetFields();
            this.$nextTick(() => {
                if (this.model != null) {
                    this.modelForm.name = this.model.name;
                }
            });
            this.visible = true;
        },
        //获取列表
        getList() {
            this.loading = true;
            resourceApi
                .sortList()
                .then((res) => {
                    this.listData = res.list;
                })
                .catch(() => { })
                .finally(() => {
                    this.loading = false;
                });
        },
        //设置排序号
        setItemOrder(row) {
            let message = this.$message({
                message: "请求中",
                iconClass: "el-icon-loading",
                customClass: "message-loading",
                duration: 0,
                offset: 60,
            });
            resourceApi
                .sortOrder({
                    id: row.id,
                    order_num: row.order_num,
                })
                .then((res) => {
                    this.$message({
                        message: "设置成功",
                        type: "success",
                        offset: 60,
                    });
                    this.getList();
                })
                .catch(() => { })
                .finally(() => {
                    message.close();
                });
        },
        //删除事件
        deleteItem(row) {
            this.$confirm("确定是否删除该项?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    let message = this.$message({
                        message: "请求中",
                        iconClass: "el-icon-loading",
                        customClass: "message-loading",
                        duration: 0,
                        offset: 60,
                    });
                    resourceApi
                        .sortDelete({
                            id: row.id,
                        })
                        .then((res) => {
                            this.$message({
                                message: "删除成功",
                                type: "success",
                                offset: 60,
                            });
                            this.getList();
                        })
                        .catch((res) => { })
                        .finally(() => {
                            message.close();
                        });
                })
                .catch(() => { });
        },
        //提交表单
        handleSubmit() {
            this.$refs.modelForm.validate((valid) => {
                if (valid) {
                    this.btnLogining = true;
                    resourceApi
                        .sortForm({
                            id: this.model == null ? 0 : this.model.id,
                            ...this.modelForm,
                        })
                        .then((res) => {
                            this.$message({
                                message: "提交成功",
                                type: "success",
                                offset: 60,
                            });
                            this.visible = false;
                            this.getList();
                        })
                        .catch((res) => { })
                        .finally(() => {
                            this.btnLogining = false;
                        });
                } else {
                    return false;
                }
            });
        }
    },
};
</script>
<style scope></style>
      